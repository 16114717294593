import { render, staticRenderFns } from "./ModalStatusLegend.vue?vue&type=template&id=dd260588&scoped=true"
import script from "./ModalStatusLegend.vue?vue&type=script&lang=js"
export * from "./ModalStatusLegend.vue?vue&type=script&lang=js"
import style0 from "./ModalStatusLegend.vue?vue&type=style&index=0&id=dd260588&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dd260588",
  null
  
)

export default component.exports