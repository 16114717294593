export default [
  {
    type: 'datepicker',
    margin: true,
    showLabel: true,
    label: 'From',
    placeholder: 'Date',
    class: 'font-small-3',
    model: null,
    locale: 'en',
    dateFormatOptions: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    },
    cols: 6,
    visible: true,
  },
  {
    type: 'datepicker',
    margin: true,
    showLabel: true,
    label: 'To',
    placeholder: 'Date',
    class: 'font-small-3',
    model: null,
    locale: 'en',
    dateFormatOptions: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    },
    cols: 6,
    visible: true,
  },
  {
    type: 'select',
    margin: true,
    showLabel: true,
    label: 'Status',
    model: null,
    options: [
      // { value: null, label: 'All' },
      // { value: 1, label: 'DRAFT' },
      // { value: 2, label: 'SENT TO HT' },
      // { value: 3, label: 'DRAFT HT' },
      // { value: 4, label: 'SENT TO CEO' },
      // { value: 5, label: 'APPROVED' },
      // { value: 6, label: 'DISAPPROVED' },
    ],
    reduce: 'value',
    selectText: 'label',
    cols: 12,
    visible: true,
  },
];
