<template>
  <b-modal
    ref="modal_create_mof"
    v-model="control"
    size="lg"
    :title="skillData.description ? skillData.description : 'Add Skill'"
    header-bg-variant="success"
    header-text-variant="dark"
    body-bg-variant="ligth"
    body-text-variant="dark"
    footer-bg-variant="ligth"
    footer-text-variant="dark"
    no-close-on-backdrop
    scrollable
    class="border-0 border-white"
    @hidden="closeModal"
  >

    <validation-observer
      ref="form"
    >

      <validation-provider
        v-slot="{ errors }"
        rules="required"
      >
        <b-form-group label="Skill Description">
          <b-form-textarea
            v-model="skill_description"
            :class="errors[0]? 'border-danger rounded' : ''"
          />
        </b-form-group>
        <span class="text-danger">{{ errors[0] }}</span>
      </validation-provider>

    </validation-observer>

    <template #modal-footer>
      <b-button
        variant="success"
        @click="insertSkill"
      >
        SEND
      </b-button>
    </template>
  </b-modal>
</template>

<script>

import MOFService from '@/views/commons/components/manual-of-functions/services/manuals-of-functions.service';

export default {

  props: {
    skillData: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      control: false,
      skill_description: '',
      skills: null,
    };
  },

  mounted() {
    this.control = true;
    this.skill_description = this.skillData.description;
  },

  created() {
    this.getSkills();
  },

  methods: {

    closeModal() {
      this.$emit('hidden');
      this.$emit('refresh-skills');
    },

    async getSkills() {
      const data = await MOFService.getSkills();
      this.skills = data.data;
    },

    async insertSkill() {
      try {
        const params = {
          id: this.skillData.id,
          description: this.skill_description,
        };

        console.log(params);

        const result = await this.$refs.form.validate();
        if (result) {
          const swal = await this.showConfirmSwal();
          if (swal.isConfirmed) {
            const data = await MOFService.insertSkills(params);
            if (data.status === 200) {
              this.showSuccessSwal('Success', 'Skill insert successfully.');
              this.closeModalSkill();
            }
          }
        }
      } catch (e) {
        console.log(e);
      }
    },

    closeModalSkill() {
      this.control = false;
      this.$emit('hidden');
      this.$emit('refresh-skills');
    },

  },

};

</script>
