<template>

  <div>
    <b-modal
      ref="modal_create_rol"
      v-model="control"
      size="sm"
      title="CREATE A NEW ROL"
      header-bg-variant="info"
      header-text-variant="dark"
      body-bg-variant="ligth"
      body-text-variant="dark"
      footer-bg-variant="ligth"
      footer-text-variant="dark"
      no-close-on-backdrop
      scrollable
      class="border-0 border-white"
      @hidden="closeModal"
    >

      <b-card no-body>
        <b-row>

          <b-col>

            <b-form-group label="Rol Name">

              <validation-observer ref="form_rol">
                <validation-provider
                  v-slot="{ errors }"
                  rules="required"
                >
                  <b-form-input
                    v-model="rol_name"
                    size="md"
                    :class="errors[0] ? 'border-danger rounded' : ''"
                    placeholder="Rol name"
                  />
                  <span class="text-danger">{{ errors[0] }}</span>
                </validation-provider>
              </validation-observer>
            </b-form-group>

          </b-col>
        </b-row>
      </b-card>

      <template #modal-footer>
        <b-button
          variant="outline-primary"
          size="sm"
          @click="insertRol"
        >
          SAVE ROL
        </b-button>
      </template>

    </b-modal>
  </div>

</template>

<script>

import ManualsOfFunctionsService from '@/views/commons/components/manual-of-functions/services/manuals-of-functions.service';

export default {

  data() {
    return {
      control: false,
      rol_name: '',
    };
  },

  mounted() {
    this.control = true;
  },

  methods: {
    closeModal() {
      this.$emit('hidden');
    },

    async insertRol() {
      const params = {
        rol_name: this.rol_name,
      };

      const result = await this.$refs.form_rol.validate();

      if (result) {
        try {
          const swal = await this.showConfirmSwal();
          if (swal.isConfirmed) {
            const response = await ManualsOfFunctionsService.insertRol(params);

            if (response.status === 200) {
              this.showSuccessSwal('SUCCESS', 'SUCCESSFULLY SAVE');
              this.$emit('hidden');
              this.$emit('refreshjobtitle');
            }
          }
        } catch (error) {
          console.log(error);
        }
      }
    },
  },
};

</script>
