<script>

export default {

  props: {
    obsData: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      observation: '',
      control: false,
    };
  },

  mounted() {
    this.observation = this.obsData;
    this.control = true;
  },

  methods: {
    closeModal() {
      this.$emit('hidden');
    },
  },
};
</script>
<template>
  <div>
    <b-modal
      ref="modal_observation_mof"
      v-model="control"
      size="md"
      title="Observation"
      header-bg-variant="danger"
      header-text-variant="dark"
      body-bg-variant="ligth"
      body-text-variant="dark"
      footer-bg-variant="ligth"
      footer-text-variant="dark"
      no-close-on-backdrop
      scrollable
      class="border-0 border-white"
      @hidden="closeModal"
    >
      <b-form-textarea
        v-model="observation"
        rows="10"
        disabled
      />

      <template #modal-footer>
        <p>
          OBSERVATION
        </p>
      </template>

    </b-modal>

  </div>
</template>
