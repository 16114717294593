<template>
  <div>
    <b-modal
      v-model="onControl"
      title="MOF Status Tracking"
      title-tag="h3"
      modal-class="modal-primary"
      size="lg"
      hide-footer
      @hidden="close"
    >
      <filter-slot
        :total-rows="totalRows"
        :paginate="paginate"
        :start-page="startPage"
        :to-page="toPage"
        :filter="filters"
        :filter-principal="{}"
        :no-visible-principal-filter="true"
        @reload="$refs['refTrackingMOFTable'].refresh()"
      >
        <template #table>
          <b-table
            ref="refTrackingMOFTable"
            no-border-collapse
            class="position-relative"
            :fields="fields"
            show-empty
            no-provider-filtering
            sticky-header="60vh"
            primary-key="id"
            responsive="sm"
            :items="searchRecruitmentStatusTracking"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :current-page="paginate.currentPage"
            :per-page="paginate.perPage"
            :busy.sync="isBusy"
          >
            <template #table-busy>
              <div class="text-center text-primary my-2">
                <b-spinner class="align-middle mr-1" />
                <strong>Loading ...</strong>
              </div>
            </template>
            <template #cell(status)="data">
              <b-row class="d-flex justify-content-center">
                <b-button
                  style="border: none; border-radius:50px !important"
                  :style="{ background: getStateColor(data.item.id_status) }"
                  @click="openRecruitmentStatus"
                >
                  <span
                    style="-webkit-text-stroke: 1px; color: black"
                    :style="(data.item.id_status === 4 || data.item.id_status === 6) && !isDarkSkin? 'color: white': ''"
                  >
                    {{ data.item.status.toUpperCase() }}
                  </span>
                </b-button>
              </b-row>
            </template>
            <template #cell(created_at)="data">
              <span>{{ data.item.created_by_name }}</span>
              <br>
              <span>{{ data.item.created_at | myGlobalWithHour }} </span>
            </template>

            <template #cell(updated_at)="data">
              <span>{{ data.item.update_by_name }}</span>
              <br>
              <span>{{ data.item.updated_at | myGlobalWithHour }} </span>
            </template>

          </b-table>
        </template>
      </filter-slot>
      <modal-status-legend
        v-if="openModalRecruitmentStatus"
        @hidden="openModalRecruitmentStatus = false"
      />
    </b-modal>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

import MOFService from '@/views/commons/components/manual-of-functions/services/manuals-of-functions.service';
import ModalStatusLegend from '@/views/commons/components/manual-of-functions/modals/ModalStatusLegend.vue';

export default {
  components: {
    ModalStatusLegend,
  },

  props: {
    mofId: { // commission that is tracked, of the table commissions
      type: String,
      default: null,
    },
  },
  data() {
    return {
      onControl: false,
      filterPrincipal: [],
      fields: [
        {
          key: 'status',
          sortable: false,
          label: 'Status',
          visible: true,
        },

        {
          key: 'created_at',
          sortable: false,
          label: 'Created by',
          visible: true,
        },
        {
          key: 'updated_at',
          sortable: false,
          label: 'Updated by',
          visible: true,
        },

      ],
      filters: [],

      totalRows: 1,
      paginate: {
        perPage: 10,
        currentPage: 1,
      },
      startPage: 0,
      toPage: 0,
      sortBy: 'created_at',
      sortDesc: true,
      searchInput: '',
      isBusy: false,
      trackingItems: [],
      openModalRecruitmentStatus: false,
    };
  },
  async created() {
    this.onControl = true;
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),
  },
  methods: {
    close() {
      this.$emit('close');
    },
    openRecruitmentStatus() {
      this.openModalRecruitmentStatus = true;
    },
    async searchRecruitmentStatusTracking(ctx) {
      const response = await MOFService.getMOFStatusTracking({

        page: ctx.currentPage,
        perPage: ctx.perPage,
        recruitment_id: this.mofId,

      });
      this.trackingItems = response.data.data;
      this.startPage = response.data.from;
      this.paginate.currentPage = response.data.current_page;
      this.paginate.perPage = response.data.per_page;
      this.totalRows = response.data.total;
      this.toPage = response.data.to;
      return this.trackingItems || [];
    },
    getStateColor(state) {
      switch (state) {
        case 1: // DRAFT
          return this.isDarkSkin ? '#ffe88d !important' : '#FFD700 !important';
        case 2: // SENT TO HUMAN TALENT
          return this.isDarkSkin ? '#9be5e5 !important' : '#00CED1 !important';
        case 3: // DRAFT BY HT
          return this.isDarkSkin ? '#ffcd85 !important' : '#FFA500 !important';
        case 4: // SENT TO CEO
          return this.isDarkSkin ? '#ca87e9 !important' : '#9400D3 !important';
        case 5: // APPROVED
          return this.isDarkSkin ? '#9fe592 !important' : '#32CD32 !important';
        case 6: // DISAPPROVED
          return this.isDarkSkin ? '#ff9473 !important' : '#FF0000 !important';
        case 7: //
          return this.isDarkSkin ? '#ffa177 !important' : '#ff4700 !important';
        default:
          return '';
      }
    },
  },
};
</script>

    <style scoped>

    </style>
