<template>
  <div>
    <b-modal
      ref="modal_create_mof"
      v-model="control"
      size="lg"
      :title="mofData.id? `EDIT MANUAL OF FUNCTIONS - ${mofData.job_name}` : 'CREATE MANUAL OF FUNCTIONS'"
      header-bg-variant="primary"
      header-text-variant="dark"
      body-bg-variant="ligth"
      body-text-variant="dark"
      footer-bg-variant="ligth"
      footer-text-variant="dark"
      no-close-on-backdrop
      scrollable
      class="border-0 border-white"
      @hidden="closeModal"
    >
      <validation-observer ref="form">
        <!-- {{ mofData }} -->
        <!-- <h3>DATOS GENERALES</h3> -->
        <b-card
          title="GENERAL DATA"
          :style="isDarkSkin ? 'background: #2d3238' : ''"
        >
          <!-- <b-col cols="6">
          <b-form-group label="JOB TITLE">
            <b-form-input
              id="jobTitleID"
              v-model="job_title"
              type="text"
              placeholder="JOB TITLE"
              required
            />
          </b-form-group>
        </b-col> -->

          <b-row>
            <b-col cols="6">

              <validation-provider
                v-slot="{ errors }"
                rules="required"
              >

                <!-- @change="validateMOF()" -->
                <b-form-group label="JOB TITLE">
                  <b-form-select
                    id="hrRolID"
                    v-model="hr_role_id"
                    :options="hr_roles"
                    :class="errors[0] ? 'border-danger rounded' : ''"
                    :disabled="!(Object.keys(mofData).length === 0)"
                    @change="validateMOF()"
                  />
                </b-form-group>
                <span class="text-danger">{{ errors[0] }}</span>
              </validation-provider>
            </b-col>
            <b-col
              cols="6"
              class="d-flex justify-content-center align-items-center"
            >
              <!-- :hidden="!(tabData === 2)" -->
              <b-button
                variant="outline-info"
                @click="openModalCreateRol"
              >
                CREATE NEW JOB TITLE
                <b-badge
                  pill
                  variant="info"
                >
                  <feather-icon icon="PenToolIcon" />
                </b-badge>
              </b-button>
            </b-col>

            <!-- <b-col cols="6">
              <validation-provider
                v-slot="{ errors }"
                rules="required"
              >
                <b-form-group label="JOB TITLE">
                  <b-form-select
                    id="hrRolID"
                    v-model="hr_role_id"
                    :options="hr_roles"
                    :class="errors[0] ? 'border-danger rounded' : ''"
                  />
                </b-form-group>
                <span class="text-danger">{{ errors[0] }}</span>
              </validation-provider>
            </b-col> -->
          </b-row>
        </b-card>

        <!-- <h3>RELACION CON OTROS PUESTOS</h3> -->
        <b-card
          title="RELATIONSHIP WITH OTHER POSITIONS"
          :style="isDarkSkin ? 'background: #2d3238' : ''"
        >
          <b-row>
            <b-col cols="6">

              <validation-provider
                v-slot="{ errors }"
                rules="required"
              >
                <b-form-group label="To whom it reports">
                  <b-form-select
                    id="toWhomReportsID"
                    v-model="toWhomReports"
                    :options="hr_roles"
                    :class="errors[0] ? 'border-danger rounded' : ''"
                  />
                </b-form-group>
                <span class="text-danger">{{ errors[0] }}</span>
              </validation-provider>
            </b-col>
            <b-col cols="6">
              <validation-provider
                v-slot="{ errors }"
                rules="required"
              >
                <b-form-group label="Who reports to him">
                  <b-form-select
                    id="whoReportsToID"
                    v-model="whoReportsTo"
                    :options="hr_roles"
                    :class="errors[0] ? 'border-danger rounded' : ''"
                  />
                </b-form-group>
                <span class="text-danger">{{ errors[0] }}</span>
              </validation-provider>

            </b-col>
          </b-row>
        </b-card>

        <!-- <h3>MISION DEL PUESTO</h3> -->
        <b-card
          title="MISSION OF THE POSITION"
          :style="isDarkSkin ? 'background: #2d3238' : ''"
        >
          <b-col cols="12">
            <b-form-group>
              <validation-provider
                v-slot="{ errors }"
                rules="required"
              >
                <b-form-textarea
                  id="missionID"
                  v-model="mission"
                  rows="5"
                  size="sm"
                  placeholder="MISSION OF THE POSITION"
                  :class="errors[0] ? 'border-danger rounded' : ''"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-card>

        <!-- <h3>FUNCIONES/ACTIVIDADES</h3> -->
        <b-card
          title="FUNCTIONS OR ACTIVITIES"
          :style="isDarkSkin ? 'background: #2d3238' : ''"
        >
          <div
            v-for="(activity, index) in activities"
            :key="index"
          >
            <b-row class="d-flex align-items-center justify-content-center">
              <b-col cols="9">
                <b-form-group>
                  <validation-provider
                    :id="'validate_' + index"
                    v-slot="{ errors }"
                    rules="required"
                  >
                    <b-form-textarea
                      :id="'mof_name_' + index"
                      v-model="activity2[index]"
                      :class="errors[0] ? 'border-danger rounded' : ''"
                      type="text"
                      :placeholder="'ACTIVITY/FUNCTION ' + (index + 1)"
                      @input="updateActivity(index, $event)"
                    />
                    <span class="text-danger">{{ errors[0] }}</span>
                  </validation-provider>

                </b-form-group>
              </b-col>
              <b-col
                cols="2"
              >
                <b-form-group class="d-flex justify-content-center">
                  <b-button
                    pill
                    variant="danger"
                    size="sm"
                    @click="removeFunction(index)"
                  >
                    <feather-icon
                      :id="'btn_'+index"
                      class="text-light"
                      icon="TrashIcon"
                      size="16"
                    />
                  </b-button>
                </b-form-group>
              </b-col>
            </b-row>
          </div>

          <!-- :hidden="!(activities.length < 10) || (activities.length === 0)" -->
          <b-button
            v-if="!(activities.length === 0)"
            id="addFunctionID"
            class="my-1 mr-1"
            size="sm"
            pill
            variant="outline-primary"
            @click="addFunction"
          >
            ADD FUNCTION (Activities)
            <feather-icon icon="PlusIcon" />

          </b-button>
          <b-col
            v-if="activities.length===0"
            cols="12"
          >
            <b-card
              border-variant="secondary"
              header-bg-variant="primary"
              header-text-variant="white"
            >
              <b-card-text>You should add functions for this position</b-card-text>
              <b-button
                :hidden="!(activities.length < 10)"
                size="sm"
                pill
                variant="success"
                @click="addFunction"
              >
                ADD FUNCTION
              </b-button>
            </b-card>
          </b-col>
        </b-card>

        <!-- <h3>AUTONOMIA DEL PUESTO</h3> -->
        <b-card
          title="AUTONOMY"
          :style="isDarkSkin ? 'background: #2d3238' : ''"
        >
          <b-col cols="6">
            <b-form-group label="AUTONOMY OF THE POSITION">

              <validation-provider
                v-slot="{ errors }"
                rules="required"
              >
                <b-form-radio-group
                  v-model="autonomy"
                  :options="options"
                  value-field="item"
                  :style="errors[0] ? 'border: 1px solid red; padding: 10px; width: 250px; border-radius: 20px' : ''"
                  text-field="name"
                  disabled-field="notEnabled"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </validation-provider>

            </b-form-group>
          </b-col>
          <b-button
            variant="outline-info"
            size="sm"
            @click="openModalAutonomy"
          >
            Autonomy legend <feather-icon icon="InfoIcon" />
          </b-button>
        </b-card>

        <!-- <h3>RELACIONES DEL PUESTO</h3> -->
        <b-card
          title="RELATIONS WITH OTHER POSITIONS"
          :style="isDarkSkin ? 'background: #2d3238' : ''"
        >
          <b-row>
            <b-col cols="6">
              <b-form-group label="INTERNAL">
                <b-input-group
                  class="mb-1"
                >
                  <b-form-input
                    v-model="internalDes"
                    size="sm"
                    maxlength="255"
                    placeholder="INTERNAL"
                    @keyup.enter="addItemInternal"
                  />
                  <b-input-group-append>
                    <b-button
                      variant="success"
                      size="sm"
                      @click="addItemInternal"
                    >
                      Add
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>

              <b-card
                v-if="internalrel != 0"
                no-body
                :class="isDarkSkin ? 'bg-dark' : 'bg-light'"
                :style="isDarkSkin ? 'border: .5px solid gray' : 'border: .5px solid #cccccc'"
                style="max-height: 300px; overflow-y: auto;"
              >
                <b-list-group flush>
                  <!--  -->
                  <b-list-group-item
                    v-for="(item, index) in internalrel"
                    :key="index"
                  >
                    <tabler-icon
                      icon="CircleIcon"
                      size="16"
                      style="color: #4caf50"
                    />
                    {{ item }}
                    <b-button
                      variant="danger"
                      size="sm"
                      pill
                      class="float-right"
                      @click="removeItemInternal(index)"
                    >
                      <tabler-icon
                        icon="TrashIcon"
                        size="16"
                      />
                    </b-button>
                  </b-list-group-item>
                </b-list-group>
              </b-card>

              <b-card
                v-else-if="showValidationInternal"
                no-body
                :class="[isDarkSkin ? 'bg-dark' : 'bg-light', 'border-danger']"
                :style="isDarkSkin ? 'border: .5px solid gray;' : 'border: .5px solid #cccccc'"
              >
                <p :class="[isDarkSkin ? 'text-light': 'text-danger', ' text-center mt-1']">
                  Internal relations is required.
                </p>
              </b-card>

            </b-col>
            <b-col
              cols="6"
            >
              <b-form-group label="EXTERNAL">
                <b-input-group
                  class="mb-1"
                >
                  <b-form-input
                    v-model="externalDes"
                    size="sm"
                    maxlength="255"
                    placeholder="EXTERNAL"
                    @keyup.enter="addItemExternal"
                  />
                  <b-input-group-append>
                    <b-button
                      variant="success"
                      size="sm"
                      @click="addItemExternal"
                    >
                      Add
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>

              <b-card
                v-if="externalrel != 0"
                no-body
                :class="isDarkSkin ? 'bg-dark' : 'bg-light'"
                :style="isDarkSkin ? 'border: .5px solid gray' : 'border: .5px solid #cccccc'"
                style="max-height: 300px; overflow-y: auto;"
              >
                <b-list-group flush>
                  <!--  -->
                  <b-list-group-item
                    v-for="(item, index) in externalrel"
                    :key="index"
                  >
                    <tabler-icon
                      icon="ArrowRightIcon"
                      size="16"
                      style="color: #0074e4"
                    />
                    {{ item }}
                    <b-button
                      variant="danger"
                      size="sm"
                      pill
                      class="float-right"
                      @click="removeItemExternal(index)"
                    >
                      <tabler-icon
                        icon="TrashIcon"
                        size="16"
                      />
                    </b-button>
                  </b-list-group-item>
                </b-list-group>
              </b-card>

              <b-card
                v-else-if="showValidationExternal"
                no-body
                :class="[isDarkSkin ? 'bg-dark' : 'bg-light', 'border-danger']"
                :style="isDarkSkin ? 'border: .5px solid gray;' : 'border: .5px solid #cccccc'"
              >
                <p :class="[isDarkSkin ? 'text-light': 'text-danger', ' text-center mt-1']">
                  External relations is required.
                </p>
              </b-card>

            </b-col>
          </b-row>
        </b-card>

        <b-card
          title="COMPETENCY PROFILE (SKILLS)"
          :style="isDarkSkin ? 'background: #2d3238' : ''"
        >
          <b-col cols="12">

            <div class="d-flex align-items-between">
              <b-col class="d-flex align-items-center">
                <b-form-checkbox
                  class="mb-1"
                  :indeterminate.sync="indeterminate"
                  @change="activeAllSkills()"
                >
                  SELECT ALL
                </b-form-checkbox>
              </b-col>
              <b-col class="text-right">
                <transition name="fade">
                  <b-button
                    v-if="currentUser.main_module === 17"
                    :variant="manageActive ? 'outline-danger' : 'outline-primary'"
                    pill
                    class="mr-1 mb-1"
                    @click="activeSkillManager()"
                  >
                    {{ manageActive? 'CLOSE MANAGE' : 'MANAGE SKILLS' }}

                  </b-button>
                </transition>
                <b-button
                  v-if="manageActive"
                  variant="outline-success"
                  class="mb-1"
                  @click="openModalSkills()"
                >
                  ADD SKILLS
                </b-button>
              </b-col>
            </div>
            <b-card
              no-body
              :class="isDarkSkin ? 'bg-dark' : 'bg-light'"
              :style="isDarkSkin ? 'border: .5px solid gray' : 'border: .5px solid #cccccc'"
              style="max-height: 300px; overflow-y: auto;"
            >

              <b-list-group class="scrollable-list">
                <b-list-group-item
                  v-for="(skill, key) in skills"
                  :key="key"
                  :class="isDarkSkin ? 'bg-dark' : 'bg-light'"
                  class="d-flex justify-content-between"
                  @click="toggleModulePermission(skill)"
                >
                  <h4>{{ skill.description }}</h4>
                  <div class="d-flex align-items-between align-items-center">

                    <b-button
                      v-if="manageActive"
                      v-b-tooltip.hover="{ title: 'Delete Skill', variant: 'danger' }"
                      variant="outline-danger"
                      size="sm"
                      class="mr-1"
                      @click.stop="deleteSkill(skill.id)"
                    >
                      <feather-icon icon="Trash2Icon" />
                    </b-button>
                    <b-button
                      v-if="manageActive"
                      v-b-tooltip.hover="{ title: 'Edit Skill', variant: 'warning' }"
                      variant="outline-warning"
                      size="sm"
                      class="mr-1"
                      @click.stop="openModalSkills(skill)"
                    >
                      <feather-icon icon="EditIcon" />
                    </b-button>

                    <b-form-checkbox
                      v-if="!manageActive"
                      v-model="skill.marked"
                      :value="true"
                      :unchecked-value="false"
                      @change="toggleModulePermission(skill)"
                    />

                  </div>
                </b-list-group-item>
              </b-list-group>

            </b-card>
          </b-col>
        </b-card>

        <!-- <h3>REQUERIMIENTOS DEL PUESTO</h3> -->
        <b-card
          title="JOB REQUIREMENTS"
          :style="isDarkSkin ? 'background: #2d3238' : ''"
        >
          <b-row>
            <b-col cols="12">
              <b-card
                title="EDUCATION"
                :style="isDarkSkin ? 'background: #42414f': 'background: #effbf0'"
              >

                <b-row>

                  <b-col>
                    <b-form-group label="MAXIMUM">
                      <validation-provider
                        v-slot="{ errors }"
                        rules="required"
                      >
                        <b-form-textarea
                          id="mof_name"
                          v-model="maximun_education"
                          :class="errors[0] ? 'border-danger rounded' : ''"
                          type="text"
                          rows="5"
                          placeholder="MAXIMUM EDUCATION"
                          required
                        />
                        <span class="text-danger">{{ errors[0] }}</span>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group label="MINIMUM">
                      <validation-provider
                        v-slot="{ errors }"
                        rules="required"
                      >
                        <b-form-textarea
                          id="mof_name"
                          v-model="minimun_education"
                          :class="errors[0] ? 'border-danger rounded' : ''"
                          type="text"
                          rows="5"
                          placeholder="MINIMUM EDUCATION"
                          required
                        />
                        <span class="text-danger">{{ errors[0] }}</span>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
            <b-col cols="12">
              <b-card
                title="FORMATION"
                :style="isDarkSkin ? 'background: #42414f': 'background: #effbf0'"
              >
                <b-row>
                  <b-col>
                    <b-form-group label="MAXIMUM">
                      <validation-provider
                        v-slot="{ errors }"
                        rules="required"
                      >
                        <b-form-textarea
                          id="mof_name"
                          v-model="maximum_formation"
                          rows="5"
                          type="text"
                          :class="errors[0] ? 'border-danger rounded' : ''"
                          placeholder="MAXIMUM FORMATION"
                          required
                        />
                        <span class="text-danger">{{ errors[0] }}</span>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group label="MINIMUM">
                      <validation-provider
                        v-slot="{ errors }"
                        rules="required"
                      >
                        <b-form-textarea
                          id="mof_name"
                          v-model="minimun_formation"
                          rows="5"
                          type="text"
                          :class="errors[0] ? 'border-danger rounded' : ''"
                          placeholder="MINIMUM FORMATION"
                          required
                        />
                        <span class="text-danger">{{ errors[0] }}</span>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                </b-row>
              </b-card>
            </b-col>
            <b-col cols="12">
              <b-card
                title="EXPERIENCE"
                :style="isDarkSkin ? 'background: #42414f': 'background: #effbf0'"
              >

                <b-row>
                  <b-col>
                    <b-form-group label="MAXIMUM">
                      <validation-provider
                        v-slot="{ errors }"
                        rules="required"
                      >
                        <b-form-textarea
                          id="mof_name"
                          v-model="maximum_experience"
                          rows="5"
                          type="text"
                          :class="errors[0] ? 'border-danger rounded' : ''"
                          placeholder="MAXIMUM EXPERIENCE"
                          required
                        />
                        <span class="text-danger">{{ errors[0] }}</span>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group label="MINIMUM">
                      <validation-provider
                        v-slot="{ errors }"
                        rules="required"
                      >
                        <b-form-textarea
                          id="mof_name"
                          v-model="minimum_experience"
                          rows="5"
                          :class="errors[0] ? 'border-danger rounded' : ''"
                          type="text"
                          placeholder="MINIMUM EXPERIENCE"
                          required
                        />
                        <span class="text-danger">{{ errors[0] }}</span>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>

          <!-- <pre>{{ mofData }}</pre> -->
        </b-card>

      </validation-observer>

      <template #modal-footer>
        <div class="w-100 d-flex justify-content-end">
          <!-- {{ mofData.mof_status_id }} -->

          <!-- for human talent -->

          <b-button
            v-if="mofData.mof_status_id === 7"
            size="sm"
            class="mr-1"
            variant="outline-danger"
            @click="openOBS"
          >
            <!--
            <feaher-icon>
              <div class="ojo">
                <div class="pupila" />
              </div>
            </feaher-icon> -->
            VIEW OBSERVATIONS
          </b-button>

          <b-button
            v-if="mofData.mof_status_id === 2 || mofData.mof_status_id === 3 || mofData.mof_status_id === 7"
            id="tooltip-draft"
            class="mr-1"
            variant="outline-primary"
            @click="shippingMethod(3)"
          >
            SAVE MOF - <b-badge
              variant="primary"
              pill
            >
              <feather-icon icon="CircleIcon" /> Draft
            </b-badge>
          </b-button>

          <b-button
            v-if="mofData.mof_status_id === 2 || mofData.mof_status_id === 3 || mofData.mof_status_id === 7"
            id="tooltip-submit-ceo"
            pill
            variant="success"
            @click="shippingMethod(4)"
          >
            SUBMIT TO CEO <feather-icon
              icon="SendIcon"
              size="15"
            />
          </b-button>

          <!-- for chiefs -->
          <b-button
            v-else
            id="tooltip-draft"
            class="mr-1"
            variant="outline-primary"
            @click="shippingMethod(validateModeToSend())"
          >
            SAVE MOF - <b-badge
              variant="primary"
              pill
            >
              <feather-icon icon="CircleIcon" /> Draft
            </b-badge>
          </b-button>

          <b-button
            v-if="mofData.mof_status_id === 1"
            id="tooltip-submit-ht"
            pill
            variant="success"
            @click="shippingMethod(2)"
          >
            SUBMIT TO HT <feather-icon
              icon="SendIcon"
              size="15"
            />
          </b-button>

          <b-button
            v-if="currentUser.user_id === 1 || (currentUser.main_module === 17 && tabData === 2 && !(mofData.mof_status_id))"
            id="tooltip-submit"
            pill
            class="ml-1"
            variant="outline-success"
            @click="shippingMethod(5)"
          >
            Approve
          </b-button>

          <b-tooltip
            target="tooltip-draft"
            placement="topright"
            triggers="hover"
            variant="primary"
          >
            Save draft
          </b-tooltip>

          <b-tooltip
            target="tooltip-submit-ceo"
            placement="topright"
            triggers="hover"
            variant="success"
          >
            Submit to CEO for review
          </b-tooltip>

          <b-tooltip
            target="tooltip-submit-ht"
            placement="topright"
            triggers="hover"
            variant="success"
          >
            Submit to Human Talent for review
          </b-tooltip>

          <b-tooltip
            target="tooltip-submit"
            placement="topright"
            triggers="hover"
            variant="success"
          >
            Approve
          </b-tooltip>
        </div>
      </template>

      <transition
        name="fade"
        mode="out-in"
      >
        <b-row
          v-if="openObs"
          class="d-flex align-items-center"
        >

          <b-col
            cols="3"
            class="fixed-top mt-2"
          >
            <b-card
              :style=" isDarkSkin ? 'background: #2d3239' : 'background: #eff4fd'"
            >

              <div class="text-right">
                <b-button
                  variant="danger"
                  pill
                  size="sm"
                  @click="closeOBS()"
                >
                  <feather-icon
                    icon="XIcon"
                  />
                </b-button>
              </div>
              <b-form-group label="OBSERVATIONS">
                <b-form-textarea
                  id="mof_name"
                  v-model="mofData.observations"
                  type="text"
                  rows="10"
                  placeholder="Write observations"
                  disabled
                />
              </b-form-group>
            </b-card>
          </b-col>
        </b-row>
      </transition>

    </b-modal>

    <modal-autonomy-legend
      v-if="showModalAutonomyLegend"
      @close="showModalAutonomyLegend = false"
    />

    <modal-create-h-r-rol
      v-if="showModalCreateRol"
      @hidden="showModalCreateRol = false"
      @refreshjobtitle="getHRRoles()"
    />

    <modal-create-h-r-rol
      v-if="showModalCreateRol"
      @hidden="showModalCreateRol = false"
      @refreshjobtitle="getHRRoles()"
    />

    <modal-skills
      v-if="showModalSkills"
      :skill-data="selectedSkill"
      @hidden="showModalSkills = false"
      @refresh-skills="getSkills(), validateMarked()"
    />

  </div>
</template>

<script>

import { mapGetters } from 'vuex';

import ManualsOfFunctionsService from '@/views/commons/components/manual-of-functions/services/manuals-of-functions.service';

import ModalAutonomyLegend from '@/views/commons/components/manual-of-functions/modals/ModalAutonomyLegend.vue';

import ModalCreateHRRol from '@/views/commons/components/manual-of-functions/modals/ModalCreateHRRol.vue';

import ModalSkills from '@/views/commons/components/manual-of-functions/modals/ModalSkills.vue';

export default {
  components: {
    ModalAutonomyLegend,
    ModalCreateHRRol,
    ModalSkills,
  },
  props: {

    mofData: {
      type: Object,
      default: () => {},
    },

    tabData: {
      type: Number,
      default: 0,
    },

    skillData: {
      type: Object,
      default: () => {},
    },

  },
  data() {
    return {

      manageActive: false,

      selectedSkill: {},
      showModalSkills: false,

      openObs: false,

      indeterminate: true,

      showModalAutonomyLegend: false,
      showModalCreateRol: false,

      internalDes: '',
      externalDes: '',

      activities: [],
      activity2: [],

      job_title: '',

      mission: '',

      autonomy: '',

      maximun_education: '',
      minimun_education: '',

      maximum_formation: '',
      minimun_formation: '',

      maximum_experience: '',
      minimum_experience: '',

      externalrel: [],
      internalrel: [],
      skills: [],

      selectedExternalRel: [],
      selectedInternalRel: [],
      // selectedSkills: [],

      selectAll: false,
      control: false,

      showValidationInternal: false,
      showValidationExternal: false,

      options: [
        { item: 'high', name: 'HIGH' },
        { item: 'medium', name: 'MEDIUM' },
        { item: 'low', name: 'LOW' },
      ],

      hr_roles: [],

      toWhomReports: null,
      whoReportsTo: null,
      hr_role_id: null,

      tab: 0,

    };
  },
  computed: {

    state() {
      return Boolean(this.value);
    },

    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),

  },
  mounted() {
    this.control = true;

    this.job_title = this.mofData.job_name ? this.mofData.job_name : '';

    this.mission = this.mofData.mission ? this.mofData.mission : '';

    this.toWhomReports = this.mofData.toWhomReports ? this.mofData.toWhomReports : '';
    this.whoReportsTo = this.mofData.whoReportsTo ? this.mofData.whoReportsTo : '';
    this.hr_role_id = this.mofData.hr_role_id ? this.mofData.hr_role_id : '';

    this.activities = this.mofData.functions ? JSON.parse(this.mofData.functions) : [];
    this.activity2 = this.mofData.functions ? JSON.parse(this.mofData.functions) : [];
    this.validateMarked();

    this.externalrel = this.mofData.relations ? JSON.parse(this.mofData.relations).external : [];
    this.internalrel = this.mofData.relations ? JSON.parse(this.mofData.relations).internal : [];

    this.autonomy = this.mofData.autonomy ? this.mofData.autonomy : '';

    this.maximun_education = this.mofData.requirements ? JSON.parse(this.mofData.requirements).maximun_education : '';
    this.minimun_education = this.mofData.requirements ? JSON.parse(this.mofData.requirements).minimun_education : '';
    this.maximum_formation = this.mofData.requirements ? JSON.parse(this.mofData.requirements).maximum_formation : '';
    this.minimun_formation = this.mofData.requirements ? JSON.parse(this.mofData.requirements).minimun_formation : '';
    this.maximum_experience = this.mofData.requirements ? JSON.parse(this.mofData.requirements).maximum_experience : '';
    this.minimum_experience = this.mofData.requirements ? JSON.parse(this.mofData.requirements).minimum_experience : '';
  },

  created() {
    this.getHRRoles();
    this.getSkills();
    this.toggleIndeterminate();
  },

  methods: {

    async validateMOF() {
      const params = {
        role_id: this.hr_role_id,
      };
      const data = await ManualsOfFunctionsService.validateMOF(params);
      return data.data.valid;
    },

    async validateMOFDisapproved() {
      const params = {
        role_id: this.hr_role_id,
      };
      const data = await ManualsOfFunctionsService.validateMOFDisapproved(params);
      return data.data.result;
    },

    activeSkillManager() {
      this.manageActive = !this.manageActive;
    },

    async deleteSkill(itemId) {
      const params = {
        id: itemId,
      };

      const result = await this.showConfirmSwal();
      if (result.isConfirmed) {
        try {
          const data = await ManualsOfFunctionsService.deletetSkill(params);
          if (data.status === 200) {
            this.showSuccessSwal();
            this.getSkills();
            this.validateMarked();
          }
        } catch (e) {
          console.log(e);
        }
      }
    },

    openModalSkills(data) {
      this.selectedSkill = data || {};
      this.showModalSkills = true;
    },

    openOBS() {
      this.openObs = !this.openObs;
    },

    closeOBS() {
      this.openObs = false;
    },

    openModalAutonomy() {
      this.showModalAutonomyLegend = true;
    },

    openModalCreateRol() {
      this.showModalCreateRol = true;
    },

    validateMarked() {
      if (typeof this.mofData.skills === 'string') {
        this.mofData.skills = JSON.parse(this.mofData.skills);
      }

      if (Array.isArray(this.skills) && Array.isArray(this.mofData.skills)) {
        this.mofData.skills.forEach(skill2 => {
          const matchingSkill = this.skills.find(s => s.id === skill2.id);
          if (matchingSkill) {
            matchingSkill.marked = skill2.marked;
          }
        });
      }
    },

    closeModal() {
      this.control = false;
      this.$emit('hidden');
    },

    removeItemInternal(index) {
      this.internalrel.splice(index, 1);
    },

    addItemInternal() {
      if (this.internalDes.length > 255) {
        this.validationLengthNeeds = true;
      } else {
        this.validationLengthNeeds = false;
        if (this.internalDes) {
          this.internalrel.push(this.internalDes);
          this.internalDes = '';
        }
      }
    },

    removeItemExternal(index) {
      this.externalrel.splice(index, 1);
    },

    addItemExternal() {
      if (this.externalDes.length > 255) {
        this.validationLengthNeeds = true;
      } else {
        this.validationLengthNeeds = false;
        if (this.externalDes) {
          this.externalrel.push(this.externalDes);
          this.externalDes = '';
        }
      }
    },

    toggleIndeterminate() {
      this.skills.forEach(item => {
        if (!item.marked) {
          this.indeterminate = false;
        }
      });
    },

    addFunction() {
      // if (this.activities.length < 10) {
      // }
      this.activities.push(''); // Agrega un nuevo elemento vacío a la lista
    },
    updateActivity(index, value) {
      this.activities[index] = value; // Actualiza el valor en la lista
    },
    removeFunction(index) {
      this.activities.splice(index, 1); // Elimina el elemento en la posición 'index'
    },

    validateModeToSend() {
      return this.tabData === 2 ? 3 : 1;
    },

    async shippingMethod(mode) {
      const params = {
        id: this.mofData.id,
        status: mode,
        user_id: this.currentUser.user_id,
        job_name: this.job_title,

        toWhomReports: this.toWhomReports,
        whoReportsTo: this.whoReportsTo,
        hr_role_id: this.hr_role_id,

        mission: this.mission,
        autonomy: this.autonomy,
        functions: this.activities,
        skills: this.skills.filter(item => item.marked === true),
        relations: {
          internal: this.internalrel,
          external: this.externalrel,
        },
        requirements: {
          maximun_education: this.maximun_education,
          minimun_education: this.minimun_education,
          maximum_formation: this.maximum_formation,
          minimun_formation: this.minimun_formation,
          maximum_experience: this.maximum_experience,
          minimum_experience: this.minimum_experience,
        },

        created_by: this.mofData.created_by,

      };

      if ((mode === 1 || mode === 5 || mode === 3) && !this.mofData.hr_role_id) {
        if (!(await this.validateMOF())) {
          this.showWarningSwal('Important',
            'The manual of functions for the selected job title already exists, try to change it or create a new role.');
          return;
        }
      }

      const result = await this.$refs.form.validate();

      if (!result || this.internalrel.length === 0
        || this.externalrel.length === 0
        || this.activities.length === 0
        || this.skills.filter(item => item.marked === true).length === 0) {
        if (this.internalrel.length === 0) {
          this.showValidationInternal = true;
        }
        if (this.externalrel.length === 0) {
          this.showValidationExternal = true;
        }

        if (this.activities.length === 0) {
          this.showWarningSwal('Activities are required');
        }

        if (this.skills.filter(item => item.marked === true).length === 0) {
          this.showWarningSwal('Skills are required');
        }

        this.$refs.form.validate().then(success => {
          if (!success) {
            setTimeout(() => {
              this.spinnerOn = false;
              const errors = Object.entries(this.$refs.form.errors)
                .map(([key, value]) => ({ key, value }))
                .filter(error => error.value.length);
              this.$refs.form.refs[errors[0].key].$el.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
              });
            }, 100);
          }
        });
        return;
      }

      if (result) {
        const swalValidation = await this.showConfirmSwal();

        try {
          if (swalValidation.isConfirmed) {
            this.addPreloader();
            const response = await ManualsOfFunctionsService.insertMOF(params);

            if (response.status === 200) {
              this.showSuccessSwal('SUCCESS', 'SUCCESSFULLY SENT');
              this.$emit('hidden');
              this.$emit('reload');
            }
            this.removePreloader();
          }
        } catch (e) {
          this.removePreloader();
          console.log(e);
        }
      }
    },

    // Dentro del método getSkills()
    async getSkills() {
      try {
        const response = await ManualsOfFunctionsService.getSkills();
        const allSkills = response.data;

        this.skills = allSkills.map(skill => ({
          id: skill.id,
          description: skill.description,
          marked: false, // Inicialmente, ninguno está marcado
        }));

        if (Array.isArray(this.mofData.skills)) {
          this.skills = this.skills.map(skill => {
            const matchingSkill = this.mofData.skills.find(s => s.id === skill.id);
            if (matchingSkill) {
              skill.marked = matchingSkill.marked;
            }
            return skill;
          });
        }
      } catch (error) {
        console.error(error);
      }
    },

    async getHRRoles() {
      const response = await ManualsOfFunctionsService.getHRRoles();
      this.hr_roles = response.data.map(role => ({
        value: role.id,
        text: role.name,
      }));
    },

    toggleModulePermission(skill) {
      skill.marked = skill.marked !== true;
    },

    activeAllSkills() {
      this.skills.forEach(skill => {
        skill.marked = !this.selectAll;
      });
      this.selectAll = !this.selectAll; // Cambia el estado de selectAll
    },

  },
};
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s !important;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0 !important;
}

.ojo {
    width: 20px;
    height: 20px;
    background-color: white;
    border-radius: 50%;
    position: relative;
    border: 5px solid #000;
    animation: ojoAnimacion 2s infinite alternate;
}

.pupila {
    width: 5px;
    height: 5px;
    background-color: #000;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: pupilaAnimacion 2s infinite alternate;
}

@keyframes ojoAnimacion {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(1.1);
    }
}

@keyframes pupilaAnimacion {
    0% {
        transform: translate(-50%, -50%) translateX(0);
    }
    100% {
        transform: translate(-50%, -50%) translateX(10px);
    }
}

</style>
