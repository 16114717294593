export default [
  {
    key: 'mof_code',
    label: 'Code',
  },
  {
    key: 'name',
    label: 'MOF NAME',
  },
  // {
  //   key: 'module',
  //   label: 'DEPARTMENT',
  // },
  {
    key: 'status',
    label: 'STATUS',
    thClass: 'text-center',
  },
  {
    key: 'name_creator',
    label: 'CREATOR',
  },
  {
    key: 'created_at',
    label: 'DATE OF CREATION',
    thClass: 'text-center',
  },
  {
    key: 'options',
    label: 'OPTIONS',
    thClass: 'text-center',
  },
];
