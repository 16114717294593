<template>
  <div>
    <b-row class="justify-content-end mr-1 mt-1">
      <!--  currentUser.user_id === 1 -->
      <b-button
        v-if="!($route.meta.tab === 3)"
        class="text-black"
        variant="success"
        @click="openModalCreateMof({})"
      >
        <b-icon icon="plus" />
        CREATE MOF
      </b-button>
    </b-row>

    <!-- filters -->
    <filter-slot
      :filter="filters"
      :filter-principal="filterPrincipal"
      :total-rows="totalRows"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      :send-multiple-sms="false"
      :change-columns-by-client="true"
      @reload="refreshTable()"
    >
      <template #buttons>
        <div class="mx-2">
          <b-row class="d-flex align-items-center">
            <b-col>
              <b-button
                href="#"
                variant="outline-info"
                size="sm"
                @click="openModalStatusLegend"
              >
                Status Legend
              </b-button>
              <!-- <h1>{{ currentUser.role_id }}</h1> -->
            </b-col>
          </b-row>
        </div>
      </template>
      <b-table
        slot="table"
        ref="refMofsList"
        :items="getMOFsProvider"
        :fields="fields"
        primary-key="id"
        table-class="text-nowrap"
        sticky-header="45vh"
        show-empty
        :current-page="paginate.currentPage"
        :per-page="paginate.perPage"
        :busy.sync="isBusy"
        :sort-by.sync="sortBy"
        :sort-desc.sync="isSortDirDesc"
        class="table-scroll-per"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-1" />
            <strong>Loading ...</strong>
          </div>
        </template>
        <!-- mof code -->
        <template #cell(mof_code)="data">
          <b-col>
            <span class="">
              {{ data.item.id }}
            </span>
          </b-col>
        </template>
        <!-- mof name -->
        <template #cell(name)="data">
          {{ data.item.job_name }}
        </template>

        <template #cell(status)="data">
          <b-row class="d-flex justify-content-center">
            <b-button
              style="border: none; border-radius: 50px !important"
              :style="{ background: getStateColor(data.item.mof_status_id) }"
              @click="openModalStatusLegend"
            >
              <span
                style="-webkit-text-stroke: 1px; color: black"
                :style="
                  (data.item.mof_status_id === 4 ||
                    data.item.mof_status_id === 6 ||
                    data.item.mof_status_id === 7) &&
                  !isDarkSkin
                    ? 'color: white'
                    : ''
                "
              >
                {{ data.item.status }}
              </span>
              <b-badge
                v-if="
                  data.item.mof_status_id === 2 || data.item.mof_status_id === 4
                "
                variant="danger"
                pill
              >
                <feather-icon icon="BellIcon" />
                <span class="sr-only">unread messages</span>
              </b-badge>
            </b-button>

            <tabler-icon
              icon="ListIcon"
              size="20"
              class="text-info cursor-pointer"
              style="margin-top: 7px; margin-left: 5px"
              @click="openModalStatusTracking(data.item)"
            />
          </b-row>
        </template>
        <template #cell(name_creator)="data">
          <span>
            <feather-icon
              icon="UserIcon"
              size="15"
              style="color: rgb(0, 183, 255)"
            />
            {{ data.item.full_name }}
          </span>
        </template>
        <template #cell(created_at)="data">
          <b-col class="text-center">
            <span>
              <feather-icon
                size="15"
                style="color: rgb(0, 183, 255)"
                icon="CalendarIcon"
              />
              {{ formatDate(data.item.created_at) }}
            </span>
          </b-col>
        </template>
        <template #cell(options)="data">
          <b-row class="d-flex justify-content-center align-items-center">
            <b-button
              v-if="
                data.item.mof_status_id === 1 ||
                (data.item.mof_status_id === 2 &&
                  currentUser.main_module === 17) ||
                (data.item.mof_status_id === 3 &&
                  currentUser.main_module === 17) ||
                (data.item.mof_status_id === 7 &&
                  currentUser.main_module === 17)
              "
              v-b-tooltip.hover.v-primary
              title="View complete MOF"
              variant="outline-primary"
              @click="openModalCreateMof(data.item)"
            >
              <feather-icon icon="EyeIcon" />
            </b-button>

            <!-- Review by Ceo -->
            <b-button
              v-else-if="
                data.item.mof_status_id === 4 && currentUser.user_id === 1
              "
              v-b-tooltip.hover.v-info
              title="Review MOF"
              variant="outline-info"
              @click="openModalReviewMofByCeo(data.item)"
            >
              <feather-icon icon="PenToolIcon" />
            </b-button>

            <!-- view mof approved -->
            <b-button
              v-else-if="
                data.item.mof_status_id === 5 &&
                (currentUser.user_id === 1 || currentUser.main_module === 17)
              "
              v-b-tooltip.hover.v-success
              title="Review Approved MOF"
              variant="outline-success"
              @click="openModalReviewMofByCeo(data.item)"
            >
              <feather-icon icon="EyeIcon" />
            </b-button>
            <b-col v-else class="text-center">
              <b-badge variant="info"> NO ACTIONS AVAILABLE </b-badge>
            </b-col>

            <b-button
              v-if="data.item.observations && currentUser.main_module === 17"
              v-b-tooltip.hover.v-danger
              class="ml-1"
              title="View MOF observations by CEO"
              variant="outline-danger"
              @click="openModalViewObsevation(data.item.observations)"
            >
              <feather-icon icon="FlagIcon" />
            </b-button>
          </b-row>

          <!-- <transition name="slide">
            <b-row v-if="rowOpenOBS[data.item.id] && data.item.observations">

              <b-col class="d-flex justify-content-center mt-1">
                <b-form-group
                  label="observation"
                >
                  <b-form-textarea
                    id="obs_txa"
                    v-model="data.item.observations"
                    disabled
                    rows="5"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </transition> -->
        </template>
      </b-table>
    </filter-slot>

    <modal-create-mof
      v-if="showModalCreateMof"
      :mof-data="selectedMOF"
      :tab-data="selectedTab"
      @hidden="showModalCreateMof = false"
      @reload="refreshTable()"
    />

    <modal-review-mof-by-ceo
      v-if="showModalReviewMofByCeo"
      :mof-data="selectedMOF"
      @hidden="showModalReviewMofByCeo = false"
      @reload="refreshTable()"
    />

    <modal-status-legend
      v-if="showModalStatusLegend"
      :mof-data="selectedMOF"
      @hidden="showModalStatusLegend = false"
      @reload="refreshTable()"
    />

    <modal-view-observation
      v-if="showModalViewObservation"
      :obs-data="selectedObs"
      @hidden="showModalViewObservation = false"
      @reload="refreshTable()"
    />

    <modal-mof-status-tracking
      v-if="showModalStatusTrackingModal"
      :mof-id="role_id"
      @close="showModalStatusTrackingModal = false"
    />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import MOFService from "@/views/commons/components/manual-of-functions/services/manuals-of-functions.service";
import fieldsMof from "@/views/commons/components/manual-of-functions/data/fields-mofs";
import filtersMof from "@/views/commons/components/manual-of-functions/data/filters-mofs";
import ModalCreateMof from "@/views/commons/components/manual-of-functions/modals/ModalCreateMof.vue";
import ModalReviewMofByCeo from "@/views/commons/components/manual-of-functions/modals/ModalReviewByCEO.vue";
import ModalStatusLegend from "@/views/commons/components/manual-of-functions/modals/ModalStatusLegend.vue";
import ModalViewObservation from "@/views/commons/components/manual-of-functions/modals/ModalViewObservation.vue";
import ModalMofStatusTracking from "@/views/commons/components/manual-of-functions/modals/ModalMofStatusTracking.vue";

export default {
  name: "GridProjects",
  components: {
    ModalCreateMof,
    ModalReviewMofByCeo,
    ModalStatusLegend,
    ModalViewObservation,
    ModalMofStatusTracking,
  },

  props: {
    mofData: {
      type: Object,
      default: () => {},
    },

    tabData: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      role_id: "",

      isSortDirDesc: true,
      sortBy: "",
      isBusy: false,
      openOBS: false,
      rowOpenOBS: {},

      selectedTab: 0,

      selectedObs: "",

      // modals
      showModalCreateMof: false,
      showModalReviewMofByCeo: false,
      showModalStatusLegend: false,
      showModalViewObservation: false,
      showModalStatusTrackingModal: false,

      selectedProjectName: "",
      selectedProjectStatus: "",
      paginate: {
        currentPage: 1,
        perPage: 10,
      },

      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Search...",
        model: "",
      },

      toPage: null,
      fields: fieldsMof,
      selectedMOF: {},
      filters: filtersMof,
      startPage: null,
      totalRows: 0,
      page: 1,
      perpage: 1,
    };
  },
  computed: {
    tab() {
      return this.$route.meta.tab;
    },

    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },

  mounted() {},
  created() {
    this.getStatusLegend();
  },

  methods: {
    async getStatusLegend() {
      const data = await MOFService.getStatusLegend();
      const formattedData = data.map((item) => ({
        value: item.id,
        label: item.name,
      }));
      formattedData.unshift({ value: null, label: "All" });
      this.filters[2].options = formattedData;
    },

    isLightColor(color) {
      const hexColor = color.replace("#", "");
      const red = parseInt(hexColor.substr(0, 2), 16);
      const green = parseInt(hexColor.substr(2, 2), 16);
      const blue = parseInt(hexColor.substr(4, 2), 16);
      const brightness = (red * 299 + green * 587 + blue * 114) / 1000;
      return brightness > 128;
    },

    getTextColor(background) {
      return this.isLightColor(background) ? "#000000" : "#ffffff";
    },

    openObservation(rowId) {
      this.$set(this.rowOpenOBS, rowId, !this.rowOpenOBS[rowId]);
    },

    getStateColor(state) {
      switch (state) {
        case 1: // DRAFT
          return this.isDarkSkin ? "#ffe88d !important" : "#FFD700 !important";
        case 2: // SENT TO HUMAN TALENT
          return this.isDarkSkin ? "#9be5e5 !important" : "#00CED1 !important";
        case 3: // DRAFT BY HT
          return this.isDarkSkin ? "#ffcd85 !important" : "#FFA500 !important";
        case 4: // SENT TO CEO
          return this.isDarkSkin ? "#ca87e9 !important" : "#9400D3 !important";
        case 5: // APPROVED
          return this.isDarkSkin ? "#9fe592 !important" : "#32CD32 !important";
        case 6: // DISAPPROVED
          return this.isDarkSkin ? "#ff9473 !important" : "#FF0000 !important";
        case 7: //
          return this.isDarkSkin ? "#ffa177 !important" : "#ff4700 !important";
        default:
          return "";
      }
    },

    formatDate(date) {
      const formattedDate = new Date(date).toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });

      return formattedDate;
    },

    openModalCreateMof(data) {
      this.selectedMOF = data || {};
      this.selectedTab = this.$route.meta.tab;
      this.showModalCreateMof = true;
    },

    openModalReviewMofByCeo(data) {
      this.selectedMOF = data || {};
      this.showModalReviewMofByCeo = true;
    },

    openModalStatusLegend() {
      this.showModalStatusLegend = true;
    },
    openModalViewObsevation(content) {
      this.selectedObs = content;
      this.showModalViewObservation = true;
    },
    openModalStatusTracking(mofitem) {
      this.showModalStatusTrackingModal = true;
      this.role_id = mofitem.hr_role_id;
    },

    async refreshTable() {
      this.$refs.refMofsList.refresh();
    },

    async getMOFsProvider() {
      const params = {
        perpage: this.paginate.perPage,
        page: this.paginate.currentPage,
        campo: this.filterPrincipal.model,
        tab: this.tab,
        user_id: this.currentUser.user_id,
        status: this.filters[2].model,
        from: this.filters[0].model,
        to: this.filters[1].model,
      };
      const data = await MOFService.getMOFs(params);
      this.items = data.data;
      this.startPage = data.from;
      this.paginate.currentPage = data.current_page;
      this.paginate.perPage = data.per_page;
      this.nextPage = this.startPage + 1;
      this.endPage = data.last_page;
      this.totalRows = data.total;
      this.toPage = data.to;
      return this.items || [];
    },
  },
};
</script>